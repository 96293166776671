.packex-form {
  .mat-checkbox-layout {
    white-space: unset;
  }

  .mat-checkbox-inner-container {
    margin-top: 5px;
  }
}

.hidden-form-field {
  border: none;
  padding: 0;
  margin: 0;
  .mat-form-field-flex {
    display: none;
  }
  .mat-form-field-underline {
    display: none;
  }
}
