@import "bootstrap/scss/bootstrap";

/* hind-vadodara-regular - latin-ext_latin */
@font-face {
  font-family: 'HindVadodara';
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  src: url('../assets/fonts/hind-vadodara-v10-latin-ext_latin/hind-vadodara-v10-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../assets/fonts/hind-vadodara-v10-latin-ext_latin/hind-vadodara-v10-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/hind-vadodara-v10-latin-ext_latin/hind-vadodara-v10-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assets/fonts/hind-vadodara-v10-latin-ext_latin/hind-vadodara-v10-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../assets/fonts/hind-vadodara-v10-latin-ext_latin/hind-vadodara-v10-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assets/fonts/hind-vadodara-v10-latin-ext_latin/hind-vadodara-v10-latin-ext_latin-regular.svg#HindVadodara') format('svg'); /* Legacy iOS */
}
/* hind-vadodara-600 - latin-ext_latin */
@font-face {
  font-family: 'HindVadodara';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/hind-vadodara-v10-latin-ext_latin/hind-vadodara-v10-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../assets/fonts/hind-vadodara-v10-latin-ext_latin/hind-vadodara-v10-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/hind-vadodara-v10-latin-ext_latin/hind-vadodara-v10-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assets/fonts/hind-vadodara-v10-latin-ext_latin/hind-vadodara-v10-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
  url('../assets/fonts/hind-vadodara-v10-latin-ext_latin/hind-vadodara-v10-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assets/fonts/hind-vadodara-v10-latin-ext_latin/hind-vadodara-v10-latin-ext_latin-600.svg#HindVadodara') format('svg'); /* Legacy iOS */
}
/* hind-vadodara-700 - latin-ext_latin */
@font-face {
  font-family: 'HindVadodara';
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  src: url('../assets/fonts/hind-vadodara-v10-latin-ext_latin/hind-vadodara-v10-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../assets/fonts/hind-vadodara-v10-latin-ext_latin/hind-vadodara-v10-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/hind-vadodara-v10-latin-ext_latin/hind-vadodara-v10-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assets/fonts/hind-vadodara-v10-latin-ext_latin/hind-vadodara-v10-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
  url('../assets/fonts/hind-vadodara-v10-latin-ext_latin/hind-vadodara-v10-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assets/fonts/hind-vadodara-v10-latin-ext_latin/hind-vadodara-v10-latin-ext_latin-700.svg#HindVadodara') format('svg'); /* Legacy iOS */
}

*, div, p, span, body {
  font-family: "HindVadodara", Helvetica, sans-serif !important;
}

h1 {
  font-family: 'HindVadodara', sans-serif;
  font-size: 1.813rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: 0.29px;
  text-align: left;
  color: var(--primary-dark);
  margin: 0;
  word-break: break-word;

  @include media-breakpoint-up(md) {
    font-size: 2.25rem;
    font-weight: bold;
    line-height: 1.24;
    letter-spacing: 0.36px;
  }
}

h2 {
  font-family: 'HindVadodara', sans-serif;
  font-size: 1.625rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: 0.26px;
  color: var(--primary-dark);
  margin: 0;
  word-break: break-word;

  @include media-breakpoint-up(md) {
    font-size: 2rem;
    line-height: 1.24;
    letter-spacing: 0.32px;
  }
}

h3 {
  font-family: 'HindVadodara', sans-serif;
  font-size: 1.438rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: 0.23px;
  color: var(--primary-dark);
  margin: 0;
  word-break: break-word;

  @include media-breakpoint-up(md) {
    font-size: 1.75rem;
    line-height: 1.24;
    letter-spacing: 0.28px;
  }
}

h4 {
  font-family: 'HindVadodara', sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.2px;
  color: var(--primary-dark);
  margin: 0;
  word-break: break-word;

  @include media-breakpoint-up(md) {
    font-size: 1.563rem;
    line-height: 1.24;
    letter-spacing: 0.25px;
  }
}

h5 {
  font-family: 'HindVadodara', sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.18px;
  color: var(--primary-dark);
  margin: 0;
  word-break: break-word;

  @include media-breakpoint-up(md) {
    font-size: 1.438rem;
    line-height: 1.24;
    letter-spacing: 0.23px;
  }
}

.subtitle-1 {
  font-family: 'HindVadodara', sans-serif;
  font-size: 1.063rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: -0.17px;
  color: var(--primary-dark);

  @include media-breakpoint-up(md) {
    font-size: 1.25rem;
    letter-spacing: -0.2px;
  }
}

.subtitle-2 {
  font-family: 'HindVadodara', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: -0.16px;
  color: var(--primary-dark);
}

p {
  font-family: 'HindVadodara', sans-serif;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
}
.buttons-navigation {
  font-family: 'HindVadodara', sans-serif;
  font-size: 0.813rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.caption {
  font-family: 'HindVadodara', sans-serif;
  font-size: 0.813rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.37;
  letter-spacing: normal;
}
