@import "variables";
@import "responsive";
@import "mixins";

html, body {
  height: 100%;
}

body {
  margin: 0;
  color: var(--primary);
  background: var(--grey-light);
  font-size: 16px;
  hyphens: auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

button {
  background: none;
  border: none;
  outline: none;
}

.mat-dialog-container {
  padding: 0 !important;
}

.box-shadow {
  @include box-shadow();
}

.no-shadow {
  box-shadow: unset !important;
}

.packex-container {
  width: 100%;
  max-width: 1440px;
  margin: auto;
}

hr, hr.small {
  max-width: 15rem;
  background: var(--info);
  height: 2px;
}

.hovered-pointer {
  &:hover {
    cursor: pointer;
  }
}

.caption {
  color: var(--primary-dark);
}

.button-navigation {
  color: var(--primary-blue-dark);
  font-family: 'HindVadodara', sans-serif;
  font-size: 0.813rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.hidden {
  display: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.position {
  span {
    width: 1.5rem;
    height: 1.5rem;
    flex-grow: 0;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    display: flex;
    border: 1px solid var(--primary-dark);
  }

  @include media-breakpoint-down(xl) {
    align-self: flex-start;
    margin-top: 0.25rem;
  }
}

.circle {
  width: 2rem;
  height: 2rem;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  &.circle-small {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.link {
  @include link
}

p {
  margin: 0;
}

.w-33 {
  width: 33% !important;
}

.w-66 {
  width: 66% !important;
}

.content-wrapper {
  margin: auto;
}

.label {
  border-radius: 70px;
  background-color: var(--grey-light);
  padding: 0.375rem 0.75rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.border-radius-4 {
  border-radius: 0.25rem;
}

@include media-breakpoint-up(md) {
  .w-md-66 {
    width: 66% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-auto {
    width: auto !important;
  }

  .h-md-100 {
    height: 100% !important;
  }
}

@include media-breakpoint-up(lg) {
  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-33 {
    width: 33% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-66 {
    width: 66% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }
}

@include media-breakpoint-up(xl) {
  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-33 {
    width: 33% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-auto {
    width: auto !important;
  }
}

.highlight-text {
  background-color: var(--tertiary);
}

.word-break-all {
  word-break: break-all;
}

.border-dashed {
  border: 1px dashed var(--grey-medium) !important;
  border-top: medium none !important;
}

.text-bold {
  font-weight: bold;
}
