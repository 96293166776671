

form {
  button:disabled,
  button[disabled]{
    background-color: var(--bs-gray-400);
    border: none;
    color: #fff;
    &:hover {
      cursor: unset;
    }
  }
}
