@import "bootstrap/scss/functions";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/modal";
@import "colors";

$theme-colors: (
  "primary-dark": $primary-dark,
  "primary-medium": $primary-medium,
  "primary-light": $primary-light,

  "secondary-dark": $secondary-dark,
  "secondary-medium-dark": $secondary-medium-dark,
  "secondary-medium": $secondary-medium,
  "secondary-medium-light": $secondary-medium-light,
  "secondary-light": $secondary-light,

  "tertiary": $tertiary,

  "error": $error,
  "warning": $warning,

  "grey-dark": $grey-dark,
  "grey-medium": $grey-medium,
  "grey-light": $grey-light,

  "overlay": $overlay,
  "overlay-dark": $overlay-dark,

  "white": $white
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 2.5,
  7: $spacer * 3,
  8: $spacer * 3.5,
  9: $spacer * 4,
  10: $spacer * 5,
  20: $spacer * 10,
);

$border-color: $grey-medium;

@import "bootstrap/scss/maps";
@import "bootstrap/scss/utilities";
