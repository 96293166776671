@use '@angular/material' as mat;
@import "../colors";

:root {
  --primary-dark: #{$primary-dark};
  --primary-medium: #{$primary-medium};
  --primary-light: #{$primary-light};

  --secondary-dark: #{$secondary-dark};
  --secondary-medium: #{$secondary-medium};
  --secondary-medium-dark: #{$secondary-medium-dark};
  --secondary-medium-light: #{$secondary-medium-light};
  --secondary-light: #{$secondary-light};

  --tertiary: #{$tertiary};

  --error: #{$error};
  --warning: #{$warning};
  --white: #{$white};

  --grey-dark: #{$grey-dark};
  --grey-medium: #{$grey-medium};
  --grey-light: #{$grey-light};

  --overlay: #{$overlay};
  --overlay-dark: #{$overlay-dark};

  --background-white: var(--white);
  --content-on-primary: var(--white);
  --background-grey: var(--grey-light);

  --special-error: #b00020;
  --special-alert: #fbac0e;
  --primary-turquoise-overlay-light: rgba(207, 220, 227, 0.5);
  --primary-turquoise-overlay-dark: rgba(159, 188, 202, 0.4);

  --primary: var(--primary-dark);
  --primary-50: #e7e7ed;
  --primary-100: var(--primary-light);
  --primary-200: var(--primary-medium);
  --primary-300: var(--primary-light);
  --primary-400: var(--primary-medium);

  --accent: var(--secondary-medium);
  --accent-50: #e8f7fc;
  --accent-100: var(--secondary-light);
  --accent-200: var(--secondary-medium);
  --accent-300: var(--secondary-light);
  --accent-400: var(--secondary-medium);

  --warn: var(--error);
  --info: var(--secondary-yellow-dark);
  --black: #000000;

  --mdc-switch-unselected-track-color: var(--secondary-light);
  --mdc-switch-selected-track-color: var(--secondary-light);
  --mdc-switch-selected-handle-color: var(--secondary-medium);

  --mdc-theme-text-primary-on-background: var(--primary-dark);
  --mdc-list-list-item-label-text-color: var(--primary-dark);

  --mdc-filled-text-field-container-color: transparent;
  --mat-form-field-state-layer-color: transparent;
}

// Material color palettes
@include mat.elevation-classes();
@include mat.app-background();

$dark-primary-text: var(--black);
$light-primary-text: var(--white);

$packex-primary-palette: (
    50: var(--primary-50),
    100: var(--primary-100),
    200: var(--primary-200),
    300: var(--primary-300),
    400: var(--primary-400),
    500: var(--primary-dark),
    600: var(--primary-600),
    700: var(--primary-700),
    800: var(--primary-800),
    900: var(--primary-900),
    A100: #7777ff,
    A200: #4444ff,
    A400: #1111ff,
    A700: #0000f6,
    contrast: (
      50: $dark-primary-text,
      100: $light-primary-text,
      200: $light-primary-text,
      300: $light-primary-text,
      400: $light-primary-text,
      500: $light-primary-text,
      600: $light-primary-text,
      700: $light-primary-text,
      800: $light-primary-text,
      900: $light-primary-text,
      A100: $light-primary-text,
      A200: $light-primary-text,
      A400: $light-primary-text,
      A700: $light-primary-text,
    )
);

$packex-accent-palette: (
  50 : var(--accent-50),
  100 : var(--accent-100),
  200 : var(--accent-200),
  300 : var(--accent-300),
  400 : var(--accent-400),
  500 : var(--accent),
  600 : var(--accent-600),
  700 : var(--accent-700),
  800 : var(--accent-800),
  900 : var(--accent-900),
  A100 : $light-primary-text,
  A200 : #d4efff,
  A400 : #a1dcff,
  A700 : #87d3ff,
  contrast: (
    50 : $dark-primary-text,
    100 : $light-primary-text,
    200 : $light-primary-text,
    300 : $light-primary-text,
    400 : $light-primary-text,
    500 : $light-primary-text,
    600 : $light-primary-text,
    700 : $light-primary-text,
    800 : $light-primary-text,
    900 : $light-primary-text,
    A100 : $light-primary-text,
    A200 : $light-primary-text,
    A400 : $light-primary-text,
    A700 : $light-primary-text,
  )
);

$packex-warn-palette: (
  50 : #f6e0e4,
  100 : #e7b3bc,
  200 : #d88090,
  300 : #c84d63,
  400 : #bc2641,
  500 : var(--error),
  600 : #a9001c,
  700 : #a00018,
  800 : #970013,
  900 : #87000b,
  A100 : #ffb3b6,
  A200 : #ff8085,
  A400 : #ff4d53,
  A700 : #ff343b,
  contrast: (
    50 : $dark-primary-text,
    100 : $dark-primary-text,
    200 : $dark-primary-text,
    300 : $light-primary-text,
    400 : $light-primary-text,
    500 : $light-primary-text,
    600 : $light-primary-text,
    700 : $light-primary-text,
    800 : $light-primary-text,
    900 : $light-primary-text,
    A100 : $dark-primary-text,
    A200 : $dark-primary-text,
    A400 : $dark-primary-text,
    A700 : $light-primary-text,
  )
);

$packex-primary: mat.m2-define-palette($packex-primary-palette, 500);
$packex-accent: mat.m2-define-palette($packex-accent-palette, 500);
$packex-warn: mat.m2-define-palette($packex-warn-palette, 500);

$packex-theme: mat.m2-define-light-theme((
  color: (
    primary: $packex-primary,
    accent: $packex-accent,
    warn: $packex-warn,
  )
));

// Emit theme-dependent styles for common features used across multiple components.
@include mat.elevation-classes();
@include mat.app-background();

// Emit styles for MatButton based on `$my-theme`. Because the configurator
// passed to `define-light-theme` omits typography, `button-theme` will not
// emit any typography styles.
@include mat.all-component-themes($packex-theme);
