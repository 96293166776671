.mat-mdc-radio-button {
  &.mat-accent {
    .mat-radio-inner-circle {
      background-color: var(--primary-dark);
    }
    &.mat-radio-checked .mat-radio-outer-circle {
      border-color: var(--primary-dark);
    }
  }

  .mdc-radio:hover .mdc-radio__native-control:not([disabled]):not(:focus)~.mdc-radio__background::before {
    opacity: 1 !important;
    background-color: var(--secondary-light);
  }

  .mdc-radio__native-control:focus+.mdc-radio__background::before {
    opacity: 1 !important;
    background-color: var(--secondary-light);
  }

  &.disabled {
    .mdc-label * {
      color: var(--grey-medium) !important;
    }
  }
}

/// MAT CHECKBOX
.mdc-checkbox__background {
  height: 1rem !important;
  width: 1rem !important;
}

.mat-mdc-checkbox {
  .mdc-checkbox__ripple {
    background: var(--secondary-light) !important;
  }

  .mdc-checkbox {
    &:hover .mdc-checkbox__native-control:not([disabled]) ~ .mdc-checkbox__ripple {
      opacity: 1 !important;
    }

    .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
      background-color: var(--primary-dark) !important;
      border-color: var(--primary-dark) !important;

      .mdc-checkbox__checkmark {
        color: var(--white) !important;
      }
    }
  }
}

.mat-mdc-checkbox-ripple, .mdc-checkbox__ripple {
  top: -2px !important;
  left: -2px !important;
}

.mat-mdc-checkbox-disabled {
  .mat-mdc-checkbox-label {
    color: var(--grey-dark);
  }

  .mat-mdc-checkbox-background {
    background-color: var(--grey-dark) !important;
  }
}

// MAT-SELECT / MAT-OPTION
.mat-mdc-option {
  .mat-pseudo-checkbox {
    display: none;
  }

  &:hover:not(.mdc-list-item--disabled),
  &:focus:not(.mdc-list-item--disabled),
  &.mat-mdc-option-active,
  &.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
    background-color: var(--secondary-light) !important;
  }

  .mdc-list-item__primary-text {
    color: var(--primary-dark);
  }
}

.mat-mdc-select-value {
  color: var(--primary-dark) !important;
}

.mat-mdc-menu-item {
  color: var(--primary-dark) !important;

  &:hover, &:focus {
    background-color: var(--secondary-light) !important;
  }
}

// MAT SLIDER
.mat-mdc-slider {
  .mdc-slider__track, .mdc-slider__track--inactive, .mdc-slider__track--active {
    height: 3px !important;
  }
}

// MAT SLIDE TOGGLE
.mat-mdc-slide-toggle {
  .mdc-switch:enabled .mdc-switch__track::after {
    background: var(--secondary-light);
  }

  .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
    background: var(--secondary-medium) !important;
  }

  .mdc-switch__icons {
    display: none;
  }

  .mdc-switch__ripple {
    height: 2.2rem !important;
    width: 2.2rem !important;
    border-radius: 50%;
  }

  .mdc-switch.mdc-switch:hover:not(:focus) .mdc-switch__ripple::before,
  .mdc-switch.mdc-switch:hover:not(:focus) .mdc-switch__ripple::after {
    opacity: 1 !important;
    background-color: var(--secondary-light) !important;
  }
}

// MAT-INPUT
.mdc-text-field--disabled.mdc-text-field--filled {
  background: transparent !important;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: var(--primary-dark) !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--grey-medium) !important;
}

input::placeholder, input::-webkit-input-placeholder, textarea::placeholder, textarea::-webkit-input-placeholder {
  color: var(--grey-dark) !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding: 0 !important;
}

.mat-mdc-form-field {
  &:not(.textarea, .mat-mdc-form-field-type-mat-chip-grid) .mat-mdc-form-field-infix {
    height: 2.5rem !important;
    min-height: auto;
    display: flex;
    align-items: center;
  }
}

textarea {
  &.mat-mdc-input-element {
    padding: 0.5rem 0;
  }
}

.textarea  {
  .mat-mdc-text-field-wrapper {
    padding-right: 0 !important;
  }
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  display: flex;
  align-items: center;
  padding: 1rem 0 0.5rem 0;
  border-top: 0;
}

.mat-mdc-form-field-invalid .mat-input-element::placeholder, .mat-mdc-form-field-invalid .mat-input-element::-webkit-input-placeholder {
  color: var(--error);
}

.mat-form-field-wrapper {
  padding-bottom: 0;
  margin: 0 !important;
}

.mat-mdc-form-field-hint-wrapper {
  padding: 0 !important;
}

.mat-mdc-form-field-subscript-wrapper {
  font-size: 75%;
}

.mat-mdc-form-field.no-subscription {
  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }
}

.packex-select {
  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }
}

.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
  position: relative !important;
  top: -1rem !important;
  padding: 0 !important;
}

.mat-mdc-select-panel {
  max-width: 60rem !important;
}

.mat-mdc-select-panel-above, .cdk-overlay-pane {
  width: auto !important;
}

button[mat-menu-item] {
  packex-download-button {
    &:hover {
      text-decoration: none !important;
    }
  }
}

.mdc-text-field {
  //padding: 0 0 0 1rem !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background: none;
}

.mat-mdc-form-field-focus-overlay {
  background: none;
}

.mdc-line-ripple {
  display: none;
}

.mat-content.mat-content-hide-toggle {
  margin-right: 0 !important;
}

.mdc-data-table__row:last-child .mdc-data-table__cell {
  border-bottom: 1px solid var(--grey-medium) !important;
}

.mat-mdc-tooltip {
  font-size: 0.8rem !important;
}

button {
  &.mat-mdc-menu-trigger {
    border-radius: 100%;
    height: 1.5rem;
    width: 1.5rem;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: var(--secondary-light);
    }
  }
}

// MAT-CHIP
.mat-mdc-chip {
  .mat-mdc-chip-remove {
    opacity: 1 !important;
  }

  &.mat-mdc-standard-chip {
    height: 2.25rem !important;
  }

  &:hover, &.cdk-focused {
    .mat-mdc-chip-focus-overlay {
      opacity: 0 !important;
    }
  }
}

.mat-mdc-standard-chip[color=accent] {
  background-color: var(--white) !important;

  .mdc-evolution-chip__text-label {
    color: var(--secondary-medium) !important;
  }

  &.mat-accent {
    border: 1px solid var(--secondary-medium);
  }

  &:hover {
    background-color: var(--secondary-light) !important;
  }

  &.mdc-evolution-chip--selected {
    background-color: var(--secondary-medium) !important;

    &.mdc-evolution-chip--disabled {
      opacity: 1 !important;
    }

    .mdc-evolution-chip__text-label {
      color: var(--white) !important;
    }

    &:hover {
      background-color: var(--secondary-light) !important;

      .mdc-evolution-chip__text-label {
        color: var(--secondary-medium) !important;
      }
    }
  }
}

.mat-mdc-standard-chip[color=primary] {
  background-color: var(--white) !important;

  .mdc-evolution-chip__text-label {
    color: var(--primary-dark) !important;
  }

  &.mat-primary {
    border: 1px solid var(--secondary-light);
  }

  &:hover {
    background-color: var(--secondary-light) !important;
  }

  &.mdc-evolution-chip--selected {
    background-color: var(--secondary-light) !important;

    &.mdc-evolution-chip--disabled {
      opacity: 1 !important;
    }

    &:hover {
      background-color: var(--secondary-light) !important;
    }

    .mdc-evolution-chip__checkmark {
      color: var(--secondary-medium) !important;
    }
  }
}
