$primary-dark : #343365;
$primary-medium : #8e87a5;
$primary-light : #917FBA;

$secondary-dark : #2a84a0;
$secondary-medium-dark: #40a8cd;
$secondary-medium: #43b9e3;
$secondary-medium-light: #b5ddef;
$secondary-light: #dceef7;

$tertiary : #fdcb68;

$error : #b00020;
$warning : #fbac0e;

$white: #ffffff;

$grey-dark: #9d9d9d;
$grey-medium: #e0e0e0;
$grey-light: #fafafa;

$overlay : rgba(207, 220, 227, 0.5);
$overlay-dark : rgba(159, 188, 202, 0.4);
