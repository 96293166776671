.mat-snack-bar-container {
  min-width: 15vw !important;
  max-width: 100vw !important;

  color: var(--white);

  &.bg-primary {
    background: var(--primary);
  }

  &.bg-error {
    background: var(--error);
  }
}
