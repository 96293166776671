$box-shadow: 0 1px 3px 0 rgba(52, 51, 101, 0.16), 0 1px 20px 0 rgba(52, 51, 101, 0.14) !important;

@mixin link {
  cursor: pointer;
  color: var(--secondary-medium);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: var(--secondary-medium);
  }
}

@mixin box-shadow {
  box-shadow: $box-shadow;
}
